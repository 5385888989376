import React, { useEffect, useState } from 'react'
import { useScroll } from 'framer-motion'
import cx from 'classnames'

import { ScrollDown } from '../../molecules'
import Header from '../common/layout/header'
import WaveAnimation from './wave-animation'
import TypeWriter from './type-writer'

import * as styles from './top.module.scss'

const Top: React.FC = () => {
  const { scrollY } = useScroll()

  const [showHeader, setShowHeader] = useState(false)

  useEffect(() => {
    scrollY.onChange((y) => {
      setShowHeader(y > 0)
    })
  }, [])

  return (
    <section className={styles.topSection}>
      <div className={styles.topCanvas}>
        <Header className={cx(styles.header, showHeader && styles.show)} path="/" />
        <WaveAnimation className={styles.wave} />
      </div>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>M. T. Personal Site</h1>
        <TypeWriter />
        <ScrollDown className={styles.scrollDown} />
      </div>
    </section>
  )
}

export default Top
