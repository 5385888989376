import React from 'react'
import cx from 'classnames'

import useTypeText from '../../../hooks/useTypeText'

import * as styles from './type-writer.module.scss'

const texts = [
  'Mitsuru Takahashi',
  'Web Developer',
  'Mobile App Developer',
  'Infra Engineer',
  'Web3 Developer',
  'Botter',
]

type Props = {
  className?: string
}

const TypeWriter: React.FC<Props> = ({ className }) => {
  const typedText = useTypeText(texts, 2500)

  return <h2 className={cx(styles.typeText, className)}>Hello! I'm&nbsp;{typedText}</h2>
}

export default TypeWriter
