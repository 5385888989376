import React from 'react'
import cx from 'classnames'

import * as styles from './wave-animation.module.scss'

type Props = {
  className?: string
}

const WaveAnimation: React.FC<Props> = ({ className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.inner}></div>
      <svg
        className={styles.waves}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        height="35vh"
        preserveAspectRatio="none"
        shapeRendering="auto"
      >
        <defs>
          <path
            fill=""
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className={styles.parallax}>
          <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 255, 255, .05)" />
          <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(0, 32, 52, .3)" />
          <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(0, 32, 52, .1)" />
          <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(0, 32, 52, 1)" />
        </g>
      </svg>
    </div>
  )
}

export default WaveAnimation
