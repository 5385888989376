import React, { useRef } from 'react'
import { useInView } from 'framer-motion'

import { Text, Button, DynamicImage } from '../../atoms'
import { PhotoFrame } from '../../molecules'
import cx from 'classnames'

import * as styles from './about.module.scss'

export const About: React.FC = () => {
  const color = 'var(--second-color)'

  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })

  return (
    <section className={styles.aboutSection}>
      <Text as="h2" textType="subTitle" color={color}>
        ABOUT
      </Text>
      <Text as="p">プロフィール</Text>
      <div ref={ref} className={cx(styles.aboutContainer, 'container')}>
        <div className={styles.photo}>
          <div className={styles.roundPhotoFrame}>
            <DynamicImage
              className={styles.roundPhoto}
              width={160}
              height={160}
              filename="mitsuru2.jpg"
              alt="author picture"
            />
          </div>
          <PhotoFrame
            className={styles.photoFrame}
            filename="mitsuru2.jpg"
            animationStart={isInView}
            animationDelay={1.2}
            width={350}
            height={350}
            alt="author picture"
            color="var(--second-color)"
          />
        </div>
        <div className={styles.myInfo}>
          <Text as="p" className={cx(styles.kanjiName, isInView && styles._show)}>
            髙橋 満
          </Text>
          <Text as="p" className={cx(styles.name, isInView && styles._show)}>
            Mitsuru Takahashi
          </Text>
          <Text as="p" className={cx(styles.description, isInView && styles._show)}>
            現在、京都を拠点にフリーランスエンジニアとして活動しています。
            <br />
            <br />
            Web、アプリ開発およびインフラ設計・構築などを業務で行っています。
            <br />
            <br />
            経歴やスキルに関しては下のリンク先で簡単にまとめています。
          </Text>
          <Button
            className={styles.detailButton}
            shape="round"
            buttonType="link"
            linkTo="/about/"
            color={color}
          >
            detail
          </Button>
        </div>
      </div>
    </section>
  )
}

export default About
