// extracted by mini-css-extract-plugin
export var _show = "about-module--_show--8nNkl";
export var aboutContainer = "about-module--aboutContainer--NHLo5";
export var aboutSection = "about-module--aboutSection--HgHJY";
export var description = "about-module--description--NSjHw";
export var detailButton = "about-module--detailButton--JOGCU";
export var kanjiName = "about-module--kanjiName--0xmN6";
export var myInfo = "about-module--myInfo--yDvDN";
export var name = "about-module--name--XrOZL";
export var photo = "about-module--photo--iOdOn";
export var photoFrame = "about-module--photoFrame--L7yFI";
export var roundPhoto = "about-module--roundPhoto--5bTXL";
export var roundPhotoFrame = "about-module--roundPhotoFrame--laKm3";