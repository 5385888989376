import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { useInView } from 'framer-motion'
import cx from 'classnames'

import { Text, Button } from '../../atoms'
import { PostCard } from '../../molecules'
import * as styles from './blog.module.scss'

import convertToExcerpt from '../../../utils/convertToExcerpt'

type Props = {
  data: Queries.HomeQuery
}

const Blog: React.FC<Props> = (props) => {
  const { data } = props
  const color = 'var(--second-color)'

  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })

  return (
    <section className={styles.blogSection}>
      <Text as="h2" textType="subTitle" color={color}>
        BLOG
      </Text>
      <Text as="p">最近の記事</Text>
      <div ref={ref} className={cx(styles.blogContainer, 'container')}>
        {data.allContentfulPost.edges.map((edge) => (
          <Link
            className={cx(styles.postCard, isInView && styles._show)}
            key={`blog-${edge.node.id}`}
            to={`/blog/${edge.node.slug}/`}
          >
            <PostCard
              title={edge.node.title || ''}
              thumbnail={edge.node.thumbnail?.id || 'no-img.jpg'}
              date={edge.node.createdAt || ''}
              description={convertToExcerpt(edge.node.content?.childMarkdownRemark?.excerpt || '')}
              timeToRead={
                edge.node.content?.childMarkdownRemark?.timeToRead
                  ? edge.node.content.childMarkdownRemark.timeToRead * 2
                  : 3
              }
            />
          </Link>
        ))}
      </div>
      <Button shape="round" buttonType="link" linkTo="/blog/" color={color}>
        detail
      </Button>
    </section>
  )
}

export default Blog
