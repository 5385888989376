import React, { useMemo, useRef } from 'react'
import { useInView } from 'framer-motion'
import { Link } from 'gatsby'
import cx from 'classnames'

import { Text, Button } from '../../atoms'
import { PhotoPocket } from '../../molecules'
import * as styles from './work.module.scss'

type Props = {
  data: Queries.HomeQuery
}

export const Work: React.FC<Props> = (props) => {
  const { data } = props
  const works = data.allContentfulWork.edges
  const displayWorks = useMemo(() => [works[1], works[0], works[2]], [works])

  const color = 'var(--primary-color)'

  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })

  return (
    <section className={styles.workSection}>
      <Text as="h2" textType="subTitle" color={color}>
        WORK
      </Text>
      <Text as="p">実績</Text>
      <div ref={ref} className={cx(styles.workContainer, 'container')}>
        {displayWorks.map((work, i) => (
          <Link key={`work-${work.node.id}`} to={`/work/#${work.node.id}`}>
            <PhotoPocket
              imgName={work.node.image?.id || 'no-img.jpg'}
              title={work.node.title || ''}
              subTitle={work.node.kind || ''}
              color={color}
              animationStart={isInView}
              animationsDelay={0.3 + 0.2 * i}
            />
          </Link>
        ))}
      </div>
      <Button shape="round" buttonType="link" linkTo="/work/" color={color}>
        detail
      </Button>
    </section>
  )
}

export default Work
