import { useState, useEffect, useRef } from 'react'

const useTypeText = (texts: string[], period: number) => {
  const loopNum = useRef<number>(0)
  const isDeleting = useRef<boolean>(false)
  const [typedText, setTypedText] = useState('')

  const tick = (text: string) => {
    const i =
      text.length === 0 ? (loopNum.current + 1) % texts.length : loopNum.current % texts.length
    const fullTxt = texts[i]

    let delta = 200 - Math.random() * 100
    if (isDeleting.current) {
      delta /= 2
    }

    if (!isDeleting.current && text === fullTxt) {
      delta = period
      isDeleting.current = true
    } else if (isDeleting.current && text === '') {
      isDeleting.current = false
      loopNum.current += 1
      delta = 500
    }

    if (isDeleting.current) {
      setTimeout(() => setTypedText((txt) => fullTxt.substring(0, txt.length - 1)), delta)
    } else {
      setTimeout(() => setTypedText((txt) => fullTxt.substring(0, txt.length + 1)), delta)
    }
  }

  useEffect(() => {
    tick(typedText)
  }, [typedText])

  return typedText
}

export default useTypeText
