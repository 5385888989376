import React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Layout, SEO } from '../components/organisms/common'
import { Top, About, Work, Blog } from '../components/organisms/home'

type Props = {
  data: Queries.HomeQuery
} & PageProps

const Home: React.FC<Props> = (props) => {
  const { data } = props

  return (
    <Layout noHeader path="/">
      <Top />
      {/* <div className={styles.siteTitle}>
        <Text as="h1" textType="title">
          Mitsuru{'\n'}
          Takahashi{'\n'}
          Personal Site
        </Text>
      </div> */}
      <About />
      <Work data={data} />
      <Blog data={data} />
    </Layout>
  )
}

export default Home

export const Head = () => {
  return <SEO />
}

export const pageQuery = graphql`
  query Home {
    allContentfulWork(limit: 3, sort: { fields: priority }) {
      edges {
        node {
          id
          title
          kind
          image {
            id
          }
        }
      }
    }
    allContentfulPost(limit: 3, sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          id
          title
          thumbnail {
            id
          }
          createdAt(formatString: "YYYY/MM/DD")
          slug
          content {
            childMarkdownRemark {
              excerpt(format: HTML)
              timeToRead
            }
          }
        }
      }
    }
  }
`
